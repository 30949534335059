import React from 'react'
import Layout from '../components/layout'
import Section from '../components/Section'
import Container from '../components/Container'
import Rsvp from '../components/rsvp'
import Header from '../components/header'

const family = () => {
  return (
    <Layout>
      <Header />
      <Section>
        <Container>
          <p>
            We are super excited to have you at our wedding at Anglers Lodge in
            the Coromandel. Here's everything you need to know!
          </p>
        </Container>
      </Section>
      <Section dark>
        <Container>
          <h2>When</h2>
          <p>
            The wedding will be held on Saturday the 28th of March 2020 at about
            2pm. Followed by a light lunch, drinks and dinner at around 6:30pm.
            We expect the majority of guests to be arriving on the Friday night
            before when we will put on a casual dinner.{' '}
          </p>
          <p>
            As our families have not met we will be holding a family dinner on
            Thursday the 26th and it would be great if you could join us.
          </p>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>Location</h2>
          <p>
            The wedding will be held at Anglers Lodge, Amodeo Bay, 15 minutes
            North of Coromandel Town on the Coromandel Peninsula. It’s a bit out
            of the way, sorry about that, but it’s pretty amazing and a place
            many New Zealanders have not been to.
          </p>
          <h3>Getting there</h3>
          <p>
            There are several options you may consider for getting to the
            wedding venue. Depending on your arrival time into Auckland, you may
            want to consider staying over night in Auckland.
          </p>
          <h4>Drive</h4>
          <p>
            Anglers Lodge is about a 3 hour drive from Auckland Airport. The
            first 2 hours are pretty easy open road or motorway. The last 1 hour
            is beautiful, but an extremely windy road. There are car rental and
            campervan companies operating out of Auckland airport.
          </p>
          <p>
            There are several companies that rent mini busses as well and you
            may want to share one between others. There will be a few spare cars
            at the wedding and you may be able to borrow one to go exploring the
            area whilst you are at the lodge.
          </p>

          <h4>Ferry</h4>
          <p>
            This is a little bit of a different option, but if driving is not
            your thing a ferry would be a scenic way to get across from
            Auckland. At the moment there are only departures from Auckland on
            Friday Afternoon and they return on Sunday, but there may be more
            weekday options published closer to the summer season. For more info
            on ferry services click here
          </p>
          <h4>Public transport</h4>
          <p>
            There is a bus service which runs from Auckland to Coromandel town.
            It leaves Auckland city, via the Airport at about 6am, costs $35 to
            get to Coromandel Town, and takes about 4.5 hours. It’s actually not
            a bad bus ride if you can get over the early start. We can then
            arrange to pick you up from Coromandel town for the final 15 minutes
            of the journey.
          </p>
          <h4>Other</h4>
          <p>
            There may be other people who are coming to the wedding with their
            own cars that you may be able to get a ride with.{' '}
          </p>
        </Container>
      </Section>
      <Section dark>
        <Container>
          <h2>Accommodation</h2>
          <p>
            Lana and I will be providing accommodation for all family on the
            Friday and Saturday nights. The accommodation will be in units or
            cabins with the best ones going to the grown ups. Most cabins have
            private bathrooms and a small kitchen.
          </p>

          <p>
            <a href="https://www.anglers.co.nz/stay/" target="_blank">
              You can see the accommodation here{' '}
            </a>
          </p>

          <p>
            Kids aka, Aaron, Carly, and Mark, we are trying to get you private
            accommodation, but it may be bunk beds. There will also be some
            large tents setup and already made up if you prefer a “Room” to
            yourself.{' '}
          </p>

          <p>
            You are welcome to stay and we would love to have you there for
            extra nights. If you do want some extra time, let us know as soon as
            you can and we will give details to Anglers Lodge who will then
            contact you for a deposit to lock in your dates. They will do their
            best to keep you in the same room so you don’t have to move around
            whilst you are there.
          </p>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>RSVP</h2>
          <p>
            Please let us know you are coming and how long you would like to
            stay for by the end of June by clicking the button below
          </p>
          <Rsvp />
        </Container>
      </Section>
      <Section dark>
        <Container>
          <h2>After</h2>
          <p>
            Lana and I are likely staying on for the Sunday night and maybe the
            Monday as well. And then we are off to Wellington. Everyone is more
            than welcome to join us down in Wellington whenever you would like.
          </p>

          <p>
            Lana and I will be away on the 10-13th of April (Easter weekend) for
            Lana’s Mum’s birthday, but if you want to hang out in Wellington
            without us, you are more than welcome to stay in our house.{' '}
          </p>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>Other Stuff</h2>

          <h3>Dress code</h3>
          <p>
            This is going to be a pretty low key affair next to the beach. A
            suit jacket and tie would be overdoing it for the boys, but if
            that’s how you want to roll, good for you!
          </p>
          <h3>Dressing room</h3>
          <p>
            For the ladies who are in tent accommodation, we will have a room
            reserved for getting ready on the day with full length mirrors, hair
            dryers/straighteners etc.
          </p>
          <h3>Dietary requirements</h3>
          <p>
            If you have an allergy that will kill your or are vegetarian/vegan
            let us know. If you are simply “intolerant” you should harden up and
            eat the deliciousness! But if it's not too weird, let us know and
            we'll ask the caterer if they can accommodate.
          </p>
          <h3>Booze</h3>
          <p>
            All booze is provided at the wedding. Limited booze provided on
            other days, think wine and beer at family dinner and a couple of
            drinks the night before the wedding. If you feel like getting on the
            piss at any time other than the wedding day you should BYO. There is
            a liquor store 15 minutes away.
          </p>
          <h3>Food</h3>
          <p>
            Dinner will be provided on the Thursday and Friday night and of
            course the wedding. Breakfast will be provided on the Friday,
            Saturday and Sunday morning, and lunch provided on the day of the
            wedding. There is a mini supermarket, bars and cafes 15 minutes away
            for anything else you need. You may also want to bring snacks to
            fight of any hanger between meals!
          </p>
          <p>
            Note: Plans may change (i.e. the fish might not bite) closer to the
            day and due to our remote location and you may be required to pick
            up some sausages on the way. We will be in touch.
          </p>
          <h3>The vibe of the thing</h3>
          <p>
            We want everyone to relax and have a nice time, as such you can do
            whatever you like whilst you are there. As we have the place to
            ourselves there will be no other guests to annoy and the wedding
            will end when it ends. It’s reasonable to expect that there will be
            the odd party going on other nights too and It’s possible that you
            may hear other people having a good time.
          </p>
          <p>
            If this sounds like your worst nightmare sleeping beauty, you may
            want to consider alternative accommodation. AirBNB and BookABach are
            great options, but you will want to book early. We will happily
            supply earplugs to help your sleep if staying onsite.
          </p>
          <h3>Around the area/Things to do</h3>
          <p>
            There is swimming, bush walks, fishing (we may have a boat and there
            are charters at the lodge), cafes, bars, abseiling, sailing and more
            (You know how to use google right?).
          </p>
          <p>
            There will be people running around and exploring the area, everyone
            is friendly so tag along.
          </p>

          <h3>Presents</h3>
          <p>
            Presents are not necessary. We appreciate the distance everyone has
            to come to attend the wedding, and are grateful to you for making
            the effort.
          </p>
        </Container>
      </Section>
    </Layout>
  )
}

export default family
